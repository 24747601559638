import * as React from "react";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Meta from "../../components/meta";
import Media from "@uizard-io/styled-media-query";

import Layout from "../../components/layout/Layout";
import Content from "../../components/layout/Content/Content";
import Headline from "../../components/texts/Headline";
import Button from "../../components/buttons/Button";

const Container = Styled.div`
  margin: 20rem 0 3rem 0;
  overflow: hidden; // fix for columns overflow

  ${Media.greaterThan("medium")`
    margin: 15rem 0 8rem 0;
  `}

  ${Media.greaterThan("large")`
    margin: 10rem 0 5rem 0;
  `}

  ${Media.lessThan("medium")`
    margin: 5rem 0 5rem 0;
  `}
`;

const Column = Styled.div`
  ${Media.greaterThan("700px")`
    min-width: 0;
  `}
`;

const ColumnText = Styled(Column)`
    max-width: calc(100% - 30rem);
    padding: 10rem 0 10rem 0;
    margin: auto;

    ${Media.lessThan("large")`
        max-width: 100%;
        padding: 5rem 0 5rem 0;
    `}
`;

const ExtendedHeadline = Styled(Headline)`
  margin-bottom: 0;
  
  ${Media.greaterThan("large")`
    margin-bottom: 5rem;
  `}
`;

const Terms = Styled.p`
    font-size: 1.8rem;
`;

const ExtendedH4 = Styled.h4`
    font-weight: bold;
`;

const ButtonSmall = Styled(Button)`
  height: 3rem;
  font-size: 1.5rem;
`;

const Row = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  font-size: 1.6rem;

  ${Media.greaterThan("large")`
    flex-direction: row;
  `}
`;

const Table = Styled.table`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-collapse: collapse;
`;

const Th = Styled.th`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-collapse: collapse;
  background-color: ${(props) => props.theme.colors.gray.lighter};
  padding: 1rem;
`;

const Td = Styled.td`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-collapse: collapse;
  padding: 1rem;
  text-align: left;
  vertical-align: top;
`;

const Privacy = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard Privacy Policy | Uizard"
      description="Explore our privacy policy and get in touch should you have any questions. The Uizard team are here to help."
      url="/privacy/"
    />

    <Content>
      <Container>
        <Content>
          <ColumnText>
            <ExtendedHeadline as="h1">Privacy Policy</ExtendedHeadline>

            <Row>
              <span>
                <i>Last updated on April the 6th of 2023</i>
              </span>

              <ButtonSmall
                onClick={() => {
                  window.print();
                }}
                solid
                variant="black"
              >
                Download
              </ButtonSmall>
            </Row>

            <Terms>
              <p>
                All defined terms in this Privacy Policy shall have the meaning
                as assigned to them in the Terms of Service unless expressly
                provided herein to the contrary.
              </p>

              <ExtendedH4 id="1general">1. General</ExtendedH4>
              <p>
                1.1. This Privacy Policy ("Policy") explains how UizardApp ApS
                and its affiliates ("Uizard", "we", "our", "us" or "our")
                processes personal data i) in connection with the use of our
                website, ii) of persons that direct inquiries to us, iii) of
                contact persons with suppliers and business partners, iv) in
                connection the provision of our online services found at{" "}
                <a href="https://uizard.io">uizard.io</a> ("Services") and as
                further defined in the Terms of Services to be found at{" "}
                <a href="https://uizard.io/terms-of-service/">
                  uizard.io/terms-of-service
                </a>{" "}
                to customers, and v) of visitors to our social media platforms,
                and vi) in connection with the Uizard Partner Program.
              </p>
              <p>
                1.2. In this Policy you can read more about the personal data we
                collect, how we handle your personal data and how long we will
                retain your personal data etc.
              </p>

              <ExtendedH4 id="2datacontroller">2. Data Controller</ExtendedH4>
              <p>
                2.1. The data controller responsible for the processing of your
                personal data is:
              </p>
              <ul>
                <li>UizardApp Aps</li>
                <li>Vestergade 29</li>
                <li>1456 Copenhagen</li>
                <li>Denmark</li>
                <li>
                  <strong>tel.:</strong> +45 5033 3445
                </li>
                <li>
                  <strong>email:</strong>{" "}
                  <a href="mailto:support@uizard.io">support@uizard.io</a>
                </li>
              </ul>
              <p>
                2.2. All questions concerning this Policy, the exercise of your
                rights or any suspected non-compliance should initially be
                directed to{" "}
                <a href="mailto:support@uizard.io">support@uizard.io</a>
              </p>
              <p>
                2.3. The general legal processing framework is Regulation (EU)
                2016/679 of the European Parliament and of the Council of 27
                April 2016 on the protection of natural persons with regard to
                the processing of personal data and on the free movement of such
                data, and repealing Directive 95/46/EC, and the attendant rules
                (the "GDPR"). In addition, the Danish Act No. 502 of 23 May 2018
                on supplementary provisions to the EU Regulation on the
                protection of natural persons with regard to the processing of
                personal data and on the free movement of such data will apply
                (the "Data Protection Act").
              </p>

              <ExtendedH4 id="3categoriesdata">
                3. Categories of Personal Data, Processing Purpose, Legal Basis
                and Retention Periods
              </ExtendedH4>
              <Table>
                <tr>
                  <Th>Data Subject</Th>
                  <Th>Personal Data</Th>
                  <Th>Purpose</Th>
                  <Th>Legal Basis</Th>
                  <Th>Retention Period</Th>
                </tr>
                <tr>
                  <Td>Visitors to the website.</Td>
                  <Td>
                    IP address, operating system, language setting, browser
                    type, equipment type, etc.
                  </Td>
                  <Td>
                    Make the website available, optimize the website and user
                    experience, ensure the functionality of the website, compile
                    statistics and conduct targeted, relevant marketing.
                  </Td>
                  <Td>
                    Consent subject to the Danish Cookie Regulation Section 3
                    (1) and GDPR Art 6 (1)(f) — Uizard's legitimate interests in
                    delivering the website, compiling statistics and marketing.
                  </Td>
                  <Td>
                    2 years for the purpose of understanding how visitors engage
                    with the Uizard Services.
                  </Td>
                </tr>
                <tr>
                  <Td>
                    Individuals that contact Uizard or otherwise communicate
                    with us.
                  </Td>
                  <Td>Contact details and content of any communication.</Td>
                  <Td>Communication, and customer support.</Td>
                  <Td>
                    GDPR Art 6 (1)(f) — Uizard's legitimate interests in
                    responding to inquiries, etc.
                  </Td>
                  <Td>
                    Up to 5 years after the year which the personal data were
                    collected unless it is necessary to store the data for a
                    longer period of time e.g., for purposes of defending any
                    legal claims.
                  </Td>
                </tr>
                <tr>
                  <Td>Contact persons with suppliers and business partners.</Td>
                  <Td>
                    Contact details, company name and content of any
                    communication.
                  </Td>
                  <Td>
                    Communication, administration of business relationship,
                    payment, documentation of orders, audit/records trail.
                  </Td>
                  <Td>
                    GDPR Art 6 (1)(f) — Uizard's legitimate interests in
                    communication with the supplier or business partner.
                  </Td>
                  <Td>
                    Up to 5 years after the year which the personal data were
                    collected unless it is necessary to store the data for a
                    longer period of time e.g., for purposes of defending any
                    legal claims.
                  </Td>
                </tr>
                <tr>
                  <Td>
                    Potential customers / contact persons with potential
                    customers.
                  </Td>
                  <Td>Contact details and content of any communication.</Td>
                  <Td>Communication.</Td>
                  <Td>
                    GDPR Art 6 (1)(f) — Uizard's legitimate interests in
                    communication with the potential customers / contact persons
                    with potential customers.
                  </Td>
                  <Td>
                    Up to 5 years after the year which the personal data were
                    collected unless it is necessary to store the data for a
                    longer period of time e.g., for purposes of defending any
                    legal claims.
                  </Td>
                </tr>
                <tr>
                  <Td>Customers / contact persons with customers.</Td>
                  <Td>
                    Contact details, company name, content of any communication
                    and relevant billing details and credit card information.
                  </Td>
                  <Td>
                    Communication, administration and documentation of customer
                    relationship, billing and dispute handling purposes.
                  </Td>
                  <Td>
                    GDPR Art 6(1)(b) — necessary for an agreement with private
                    customer and GDPR Art 6(1)(f) — Uizard's legitimate interest
                    in maintaining the customer relationship and communicating
                    with contact persons, delivering the service, billing and
                    establishment, exercise and defence of legal claims.
                  </Td>
                  <Td>
                    Up to 5 years after the year which the personal data were
                    collected unless it is necessary to store the data for a
                    longer period of time e.g., for purposes of defending any
                    legal claims.
                  </Td>
                </tr>
                <tr>
                  <Td>Partner program contact persons.</Td>
                  <Td>Partner IP, contact details, company name and content of any communication, financial information; and/or certain information about Partner's end users (such as IP address and device identifier).</Td>
                  <Td>Communication, administration of business relationship, documentation of transactions, analytics purposes, audit/records trail.</Td>
                  <Td>GDPR Art 6 (1)(f) — Uizard's legitimate interests in communication with the supplier or business partner.</Td>
                  <Td>Up to 5 years after the year which the personal data were collected unless it is necessary to store the data for a longer period of time e.g., for purposes of defending any legal claims.</Td>
                </tr>
                <tr>
                  <Td>All of the above.</Td>
                  <Td>All of the above.</Td>
                  <Td>
                    Compliance with legal obligations, implementation and
                    maintenance of organisational and technical security
                    measures, handling enquiries or complaints, handling
                    disputes and when necessary for the establishment, exercise
                    or defence of legal claims.
                  </Td>
                  <Td>
                    GDPR Art 6 (1)(f) — Uizard's legitimate interests in
                    compliance with legal obligations, implementation and
                    maintenance of organisational and technical security
                    measures, handling enquiries or complaints, handling
                    disputes and when necessary for the establishment, exercise
                    or defence of legal claims.
                  </Td>
                  <Td>
                    Up to 5 years after the year which the personal data were
                    collected unless it is necessary to store the data for a
                    longer period of time e.g., for purposes of defending any
                    legal claims.
                  </Td>
                </tr>
              </Table>

              <ExtendedH4 id="4sharingdata">
                4. Sharing Your Personal Data
              </ExtendedH4>
              <p>
                4.1. We may share your personal data with our third-party
                service providers such as, for example, our IT hosting services
                providers, our providers of technical support, etc.
              </p>
              <p>
                4.2. We may also share your data with our group
                companies/entities to the extent that we are entitled to do so
                under the law.
              </p>
              <p>
                4.3. In addition, we will share your data to the extent that we
                are required to do so, for example as a result of requirements
                to report information to public authorities.
              </p>

              <ExtendedH4 id="5sharingdatanoneu">
                5. Sharing Your Personal Data With Non-EU/EEA Recipients
              </ExtendedH4>
              <p>
                5.1. Some of our service providers are located outside the
                EU/EEA. We therefore may share your personal data with
                non-EU/EEA recipients. However, this will require:
              </p>
              <ul>
                <li>
                  That an adequacy decision has been issued by the European
                  Commission for the level of protection offered by the country
                  or the international company in question;
                </li>
                <li>
                  That the standard contractual clauses on data protection
                  adopted by the European Commission have been entered into
                  between us and the recipient of your personal data;
                </li>
                <li>
                  That the recipient in question is certified in accordance with
                  Article 42 of the GDPR; or
                </li>
                <li>
                  That the recipient in question has adopted a set of binding
                  corporate rules.
                </li>
              </ul>
              <p>
                5.2. We may also sometimes ask for your consent to transfer your
                personal data to non-EU/EEA recipients, or such transfer may
                sometimes be necessary for the performance of an agreement with
                you, or the implementation of pre-contractual measures taken at
                your request. Such exemptions for specific situations are
                governed by Article 49 of the GDPR.
              </p>
              <p>
                5.3. You are entitled to information about or a copy of any
                appropriate safeguards which form the basis of the transfer of
                personal data to non-EU/EEA recipients or — in the case of
                exemptions provided under Article 49 of the GDPR — the
                exemptions which serve as the basis for such transfer.
              </p>

              <ExtendedH4 id="6yourrights">6. Your Rights</ExtendedH4>
              <p>6.1. The list below includes an overview of your rights.</p>
              <ul>
                <li>
                  <strong>Access:</strong> You have the right to access the
                  personal data we process about you. You may request access to
                  the personal data we hold about you, including the purposes
                  for which the data were collected.
                </li>
                <li>
                  <strong>Rectification and erasure:</strong> You have the right
                  to request rectification, supplementary processing, erasure or
                  blocking of the personal data we process about you.
                </li>
                <li>
                  <strong>Restriction of processing:</strong> In certain
                  circumstances, you have the right to restrict the processing
                  of your personal data.
                </li>
                <li>
                  <strong>Data portability:</strong> You have the right to
                  receive your personal data (only data about you which you
                  yourself have provided to us) in a structured, commonly used
                  and machine-readable format (data portability).
                </li>
                <li>
                  <strong>Right to object:</strong> You have the right to ask us
                  not to process your personal data in cases where our
                  processing is based on Article 6(1)(f) (legitimate interests).
                  The extent to which we process your personal data for such
                  purposes is described in this Policy.
                </li>
                <li>
                  <strong>Withdrawal of consent:</strong> If the processing of
                  your personal data is based on your consent, you have the
                  right to withdraw such consent at any time.
                </li>
              </ul>
              <p>
                6.2. Your exercise of the above rights may be subject to
                conditions or restrictions.
              </p>

              <ExtendedH4 id="7secureprocessing">
                7. Secure Processing
              </ExtendedH4>
              <p>
                7.1. At Uizard, our processing of personal data is governed by
                our Information Security Requirements, which is implemented to
                ensure that all data is handled in a manner that complies with
                information security best practices.
              </p>

              <ExtendedH4 id="8cookies">8. Cookies Policy</ExtendedH4>
              <p>
                8.1. Cookies are small text files that are placed on your
                computer or mobile device when you visit a website, mobile app
                or use an online product. We use cookies to enhance your user
                experience when using the Uizard Services, and to show you ads
                related to Uizard online.
              </p>
              <p>
                8.2. You can decide to delete non-essential cookies at any time.
                Deleting cookies or similar technologies means that any
                preference settings you have made on a particular website will
                be lost and the functionality of the Uizard Services may be
                impaired. You can use the preference settings in your web
                browser to:
              </p>
              <ul>
                <li>
                  See what cookies or other similar technologies have been
                  stored on your device;
                </li>
                <li>Block cookies or similar technologies;</li>
                <li>
                  Clear cookies or similar technologies from your browser.
                </li>
              </ul>
              <p>
                For more information on how to manage cookies on the most
                popular browsers, please see below:
              </p>
              <ul>
                <li>
                  <a href="https://support.google.com/chrome/answer/95647?hl=en">
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?">
                    Firefox
                  </a>
                </li>
                <li>
                  <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                    Microsoft Edge
                  </a>
                </li>
                <li>
                  <a href="https://support.apple.com/en-au/guide/safari/sfri11471/">
                    Safari
                  </a>
                </li>
              </ul>
              <p>
                Moreover, you may opt out of advertising partners' targeted
                advertising using the following links:
              </p>
              <ul>
                <li>
                  <a href="http://www.aboutads.info/choices/">
                    http://www.aboutads.info/choices/
                  </a>{" "}
                  and{" "}
                  <a href="http://www.networkadvertising.org/choices/">
                    http://www.networkadvertising.org/choices/
                  </a>{" "}
                  if located in the United States
                </li>
                <li>
                  <a href="http://www.youronlinechoices.eu/">
                    http://www.youronlinechoices.eu/
                  </a>{" "}
                  if located in the European Union.
                </li>
              </ul>

              <Table>
                <tr>
                  <Th>Provider</Th>
                  <Th>Name</Th>
                  <Th>Purpose</Th>
                  <Th>Expiration</Th>
                </tr>
                <tr>
                  <Td>Google Analytics</Td>
                  <Td>_ga</Td>
                  <Td>
                    We use Google Analytics, which is a web analytics tool that
                    helps us understand how users engage with the Uizard
                    Services. Like many services, Google Analytics uses cookies
                    to track user interactions, as in our case, where they are
                    used to collect information about how users use the Uizard
                    Services. This information is used to compile reports and to
                    help us improve our Services. The reports disclose website
                    trends without identifying individual visitors. For more
                    information on Google Tag Manager, see{" "}
                    <a href="https://support.google.com/tagmanager/answer/6102821?">
                      here
                    </a>
                    . For more information on Google Analytics, see{" "}
                    <a href="https://policies.google.com/technologies/partner-sites">
                      here
                    </a>
                    . If you want to opt out of Google Analytics across all
                    websites you use, see{" "}
                    <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
                      here
                    </a>
                    .
                  </Td>
                  <Td>2 years</Td>
                </tr>
                <tr>
                  <Td>Google Analytics</Td>
                  <Td>_gat</Td>
                  <Td>
                    Used by Google Analytics to throttle the request rate.
                  </Td>
                  <Td>1 day</Td>
                </tr>
                <tr>
                  <Td>Google Analytics</Td>
                  <Td>_gid</Td>
                  <Td>
                    Used to generate statistical data on how the visitor uses
                    the website.
                  </Td>
                  <Td>1 day</Td>
                </tr>
                <tr>
                  <Td>Google AdWords</Td>
                  <Td>ads/ga-audiences</Td>
                  <Td>
                    Used by Google AdWords to re-engage visitors that are likely
                    to convert to customers based on the visitor's online
                    behaviour across websites.
                  </Td>
                  <Td>Session</Td>
                </tr>
                <tr>
                  <Td>Meta (Facebook and Instagram)</Td>
                  <Td>tr</Td>
                  <Td>
                    We use the pixel function of Facebook Custom Audiences to
                    identify users who have landed on certain pages for the
                    purposes of target group-orientated remarketing and
                    advertising. The Facebook pixel stores a cookie, which means
                    that information about your visit to the Uizard Services
                    will be shared with Facebook. These cookies, and the
                    information collected, are used to help Facebook deliver
                    advertising on our behalf to users who have previously
                    visited our Services when they are on Facebook or another
                    platform powered by Facebook Advertising, and to track
                    advertising success. To modify your Facebook Ad settings to
                    restrict these advertisements and to learn more, see{" "}
                    <a href="https://www.facebook.com/policies/cookies/">
                      here
                    </a>
                    .
                  </Td>
                  <Td>Session</Td>
                </tr>
                <tr>
                  <Td>Facebook</Td>
                  <Td>_fbp</Td>
                  <Td>
                    Used by Facebook to deliver a series of advertisement
                    products such as real time bidding from third party
                    advertisers.
                  </Td>
                  <Td>3 months</Td>
                </tr>
                <tr>
                  <Td>LinkedIn</Td>
                  <Td>bscookie</Td>
                  <Td>
                    Used by the social networking service, LinkedIn, for
                    tracking the use of embedded services.
                  </Td>
                  <Td>2 years</Td>
                </tr>
                <tr>
                  <Td>LinkedIn</Td>
                  <Td>lang</Td>
                  <Td>
                    Set by LinkedIn when a web page contains an embedded "Follow
                    us" panel.
                  </Td>
                  <Td>Session</Td>
                </tr>
                <tr>
                  <Td>LinkedIn</Td>
                  <Td>lidc</Td>
                  <Td>
                    Used by the social networking service, LinkedIn, for
                    tracking the use of embedded services.
                  </Td>
                  <Td>1 day</Td>
                </tr>
                <tr>
                  <Td>LinkedIn</Td>
                  <Td>bcookie</Td>
                  <Td>
                    Used by the social networking service, LinkedIn, for
                    tracking the use of embedded services.
                  </Td>
                  <Td>2 years</Td>
                </tr>
                <tr>
                  <Td>LinkedIn</Td>
                  <Td>UserMatchHistory</Td>
                  <Td>
                    Used to track visitors on multiple websites, in order to
                    present relevant advertisement based on the visitor's
                    preferences.
                  </Td>
                  <Td>29 days</Td>
                </tr>
                <tr>
                  <Td>Twitter</Td>
                  <Td>personalization_id</Td>
                  <Td>
                    This cookie is set by Twitter — The cookie allows the
                    visitor to share content from the website onto their Twitter
                    profile.
                  </Td>
                  <Td>2 years</Td>
                </tr>
                <tr>
                  <Td>Zendesk</Td>
                  <Td>__zlcstore</Td>
                  <Td>
                    This cookie is served by Zendesk, a service that we use in
                    order to provide a customer support chat to the visitors of
                    the website. This cookie is necessary for the chat-box
                    function on the website to function.
                  </Td>
                  <Td>Persistent</Td>
                </tr>
                <tr>
                  <Td>Zendesk</Td>
                  <Td>__cfruid</Td>
                  <Td>
                    This cookie is served by Zendesk, a service that we use in
                    order to provide a customer support chat to the visitors of
                    the website. This cookie is a part of the services provided
                    by Cloudflare — Including load-balancing, deliverance of web
                    site content and serving DNS connection for website
                    operators.
                  </Td>
                  <Td>Session</Td>
                </tr>
                <tr>
                  <Td>Zendesk</Td>
                  <Td>ZD-suid</Td>
                  <Td>
                    Unique id that identifies the user's session when using the
                    customer support chat on the website.
                  </Td>
                  <Td>20 minutes</Td>
                </tr>
                <tr>
                  <Td>Zendesk</Td>
                  <Td>ZD-store</Td>
                  <Td>
                    Registers whether the self-service-assistant Zendesk Answer
                    Bot has been displayed to the website user.
                  </Td>
                  <Td>Persistent</Td>
                </tr>
                <tr>
                  <Td>Zendesk</Td>
                  <Td>ZD-buid</Td>
                  <Td>
                    Unique id that identifies the user on recurring visits to
                    provide customer support through a chat.
                  </Td>
                  <Td>Persistent</Td>
                </tr>
                <tr>
                  <Td>Intercom</Td>
                  <Td>intercom-id-[app-id]</Td>
                  <Td>Unique id to identify users anonymously.</Td>
                  <Td>9 months</Td>
                </tr>
                <tr>
                  <Td>Intercom</Td>
                  <Td>intercom-session-[app-id]</Td>
                  <Td>
                    Identifier for each unique browser session. This session
                    cookie is refreshed on each successful logged-in ping,
                    extending it to 1 week from that moment. The user can access
                    their conversations and have data communicated on logged out
                    pages for 1 week, as long as the session isn't intentionally
                    terminated upon logout.
                  </Td>
                  <Td>1 week</Td>
                </tr>
                <tr>
                  <Td>Stripe</Td>
                  <Td>__stripe_mid</Td>
                  <Td>
                    This cookie is necessary for making credit card transactions
                    on the website. The service is provided by Stripe.com which
                    allows online transactions without storing any credit card
                    information.
                  </Td>
                  <Td>1 year</Td>
                </tr>
                <tr>
                  <Td>Stripe</Td>
                  <Td>__stripe_sid</Td>
                  <Td>
                    This cookie is necessary for making credit card transactions
                    on the website. The service is provided by Stripe.com which
                    allows online transactions without storing any credit card
                    information.
                  </Td>
                  <Td>1 day</Td>
                </tr>
                <tr>
                  <Td>Stripe</Td>
                  <Td>m</Td>
                  <Td>
                    Determines the device used to access the website. This
                    allows the Stripe payment service content to be formatted
                    accordingly.
                  </Td>
                  <Td>2 years</Td>
                </tr>
                <tr>
                  <Td>Amazon Web Services</Td>
                  <Td>AWSALB</Td>
                  <Td>
                    Registers which server-cluster is serving the visitor. This
                    is used in context with load balancing, in order to optimize
                    user experience.
                  </Td>
                  <Td>6 days</Td>
                </tr>
                <tr>
                  <Td>Amazon Web Services</Td>
                  <Td>AWSALBCORS</Td>
                  <Td>
                    Registers which server-cluster is serving the visitor. This
                    is used in context with load balancing, in order to optimize
                    user experience.
                  </Td>
                  <Td>6 days</Td>
                </tr>
                <tr>
                  <Td>Pendo</Td>
                  <Td>_pendo_meta</Td>
                  <Td>
                    We use Pendo to improve the website and optimize user
                    experience.
                  </Td>
                  <Td>Persistent</Td>
                </tr>
                <tr>
                  <Td>Pendo</Td>
                  <Td>_pendo_visitorId</Td>
                  <Td>
                    Unique id that identifies the user. We use Pendo to improve
                    the website and optimize user experience.
                  </Td>
                  <Td>Persistent</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>X-Experiment</Td>
                  <Td>
                    Used to track which version of the website each visitor saw
                    in order to optimize the website and perform A/B
                    experiments.
                  </Td>
                  <Td>Session</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>Locale</Td>
                  <Td>
                    Used to track the preferred browser language of the visitor.
                  </Td>
                  <Td>Session</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>cookie-disclaimer</Td>
                  <Td>
                    Used to track if the cookie disclaimer has been shown to a
                    user.
                  </Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>referral</Td>
                  <Td>Used to track referrals from other users.</Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>ad-tracking-entries</Td>
                  <Td>Used for general ad tracking.</Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>ad-campaign</Td>
                  <Td>Used for ad campaign tracking.</Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>affiliate</Td>
                  <Td>Used for affiliate tracking.</Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>auth:redirect</Td>
                  <Td>
                    Redirect users to their desired page after authentication.
                  </Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>deviceId</Td>
                  <Td>
                    Unique identifier for the browser used for tracking events.
                  </Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>token</Td>
                  <Td>Authentication token used for user sessions.</Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>browserUpdateDialogShown</Td>
                  <Td>
                    Keep track of whether the browser compatibility dialog has
                    been shown to the user or not.
                  </Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>noTrack</Td>
                  <Td>
                    If set will prevent most user tracking. Used for development
                    purposes.
                  </Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>auth:email</Td>
                  <Td>
                    Used to pre-populate the email field in the login form when
                    a user logs in or signs up as a result of an invitation to
                    join Uizard by another user.
                  </Td>
                  <Td>20 years</Td>
                </tr>
                <tr>
                  <Td>Uizard</Td>
                  <Td>auth:action</Td>
                  <Td>
                    Determines an action that the website should carry out after
                    a user authenticates.
                  </Td>
                  <Td>20 years</Td>
                </tr>
              </Table>

              <ExtendedH4 id="9complaints">
                9. Complaints to Supervisory Authority
              </ExtendedH4>
              <p>
                9.1. Any complaint about our processing of your personal data
                may be submitted to the Danish Data Protection Agency:
              </p>
              <ul>
                <li>The Danish Data Protection Agency</li>
                <li>Carl Jacobsensvej 35</li>
                <li>2500 Valby</li>
                <li>Denmark</li>
                <li>
                  <strong>tel.:</strong> +45 3319 3200
                </li>
                <li>
                  <strong>email:</strong>{" "}
                  <a href="mailto:dt@datatilsynet.dk">dt@datatilsynet.dk</a>
                </li>
              </ul>

              <ExtendedH4 id="10amendments">
                10. Amendments and Updates
              </ExtendedH4>
              <p>
                10.1. We reserve the right to amend this Policy as and when
                needed. We encourage you to periodically review this page for
                the latest information on the Policy.
              </p>
              <p>
                10.2. This version of the Policy is effective December the 21st
                of 2021.
              </p>
            </Terms>
          </ColumnText>
        </Content>
      </Container>
    </Content>
  </Layout>
);

export default Privacy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

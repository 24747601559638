import Media from '@uizard-io/styled-media-query';
import Styled from 'styled-components/macro';

export interface HeadlineProps {
  noRightPadding?: boolean;
}

const Headline = Styled.h3<HeadlineProps>`
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 0 0 1.5rem 0;
  
  ${Media.greaterThan('large')`
    font-size: 4.6rem;
    line-height: 4.8rem;
    margin: 0 0 3rem 0;
    padding-right: ${(props: any) => (props.noRightPadding ? '0' : '4rem')} ;
  `}
`;

export default Headline;
